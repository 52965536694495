<template>
  <v-menu offset-y v-model="showMenu">
    <template #activator="{ on }">
      <v-btn
        v-on="on"
        text
        :color="btnTitle ? 'accent' : 'btnIcon'"
        class="cycles-list-btn px-0"
        title="Печать"
        :loading="loading"
        :ripple="false"
      >
        <v-icon left class="mr-0" size="20">mdi-printer-outline</v-icon>
        <span class="ml-2" v-if="btnTitle">{{ btnTitle }}</span>
        <v-icon right class="ml-0">{{ showMenu ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item dense target="_blank" :to="reportApi.dpoListenerListForDepartmentNeeds(cycleId)">
        <v-list-item-title class="list-text">
          Список слушателей для нужд кафедры
        </v-list-item-title>
      </v-list-item>

      <v-list-item dense target="_blank" :to="reportApi.dpoListenerListForManagementDivision(cycleId)">
        <v-list-item-title class="list-text">
          Список слушателей учебного управления
        </v-list-item-title>
      </v-list-item>

      <v-list-item dense target="_blank" :to="reportApi.dpoListenerListForComplectationDivision(cycleId)">
        <v-list-item-title class="list-text">
          Список слушателей отдела комплектования
        </v-list-item-title>
      </v-list-item>

      <v-list-item dense target="_blank" :to="reportApi.dpoListenerListReceivedCert(cycleId)">
        <v-list-item-title class="list-text">
          Список слушателей получивших итоговый документ
        </v-list-item-title>
      </v-list-item>

      <v-spacer class="mx-4 my-1" />

      <v-list-item dense @click="openChooseLecturerDialog">
        <v-list-item-title class="list-text">
          Учёт педнагрузки преподавателя
        </v-list-item-title>
      </v-list-item>

      <v-list-item dense target="_blank" :to="reportApi.dpoTeacherWorkloadByCycle(cycleId)">
        <v-list-item-title class="list-text">
          Нагрузка преподавателей цикла
        </v-list-item-title>
      </v-list-item>

      <v-list-item dense target="_blank" :to="reportApi.dpoCycleAttendance(cycleId)">
        <v-list-item-title class="list-text">
          Учёт посещаемости
        </v-list-item-title>
      </v-list-item>

      <v-list-item dense target="_blank" :to="reportApi.dpoListenerListSheetIssueCert(cycleId)">
        <v-list-item-title class="list-text">
          Ведомость на выдачу итогового документа
        </v-list-item-title>
      </v-list-item>

      <v-list-item dense target="_blank" :to="reportApi.dpoExamProtocol(cycleId)">
        <v-list-item-title class="list-text">
          Протокол итоговой аттестации
        </v-list-item-title>
      </v-list-item>

      <v-spacer class="mx-4 my-1" />

      <v-list-item dense @click="openPrintContractsDialog">
        <v-list-item-title class="list-text">
          Печать договоров
        </v-list-item-title>
      </v-list-item>
    </v-list>

    <ChooseLecturerDialog
      :dialog="dialog"
      :cycleId="cycleId"
      :lecturersList="lecturersList"
      @closeDialog="closeDialog"
    />

    <PrintContractsDialog
      :dialog="printContractsDialog"
      :cycleId="cycleId"
      :listenersList="listenersList"
      @closeDialog="closePrintContractsDialog"
    />
  </v-menu>
</template>

<script>
import { reportApi, scheduleApi, cycleApi } from '@/api'
import ChooseLecturerDialog from '@/components/cycles/dialogs/ChooseLecturer'
import PrintContractsDialog from '@/components/cycles/dialogs/printContracts/Dialog'

export default {
  name: 'ReportMenu',

  components: {
    ChooseLecturerDialog,
    PrintContractsDialog
  },

  props: {
    cycleId: Number,
    btnTitle: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    reportApi,
    showMenu: false,
    dialog: false,
    loading: false,
    lecturersList: [],

    printContractsDialog: false,
    listenersList: []
  }),

  methods: {
    async openChooseLecturerDialog() {
      try {
        this.loading = true
        const data = await scheduleApi.getLecturersListByCycleId(this.cycleId)

        if (data?.length) {
          this.lecturersList = data.map(el => ({ ...el, subtitle: `${el.emplTitleCode} / ${el.emplWorkType}`}))
          this.dialog = true
        } else {
          this.$toastr('warning', '', 'Нет преподавателей для выбора')
        }
      } catch (e) {
        if (e.status !== 500) {
          this.$toastr('error', `<b>Код ошибки: ${e.status}</b>`, e.data.message)
        }
      } finally {
        this.loading = false
      }
    },

    async openPrintContractsDialog() {
      try {
        this.loading = true
        this.listenersList = await cycleApi.getListenersByCycleId(this.cycleId)
        this.printContractsDialog = true
      } catch (e) {
        if (e.status !== 500) {
          this.$toastr('error', `<b>Код ошибки: ${e.status}</b>`, e.data.message)
        }
      } finally {
        this.loading = false
      }
    },

    closeDialog() {
      this.lecturersList = []
      this.dialog = false
    },

    closePrintContractsDialog() {
      this.listenersList = []
      this.printContractsDialog = false
    },
  }
}
</script>