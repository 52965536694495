<template>
  <v-row justify="center" class="ma-0">
    <v-dialog :value="dialog" max-width="500" persistent @input="closeDialog">
      <v-card class="dialog-card">
        <v-card-title class="headline accent--text pb-4">
          Учёт педнагрузки преподавателя
        </v-card-title>

        <v-card-text outlined tile class="border-top">
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0 pt-5 px-0">
                <v-autocomplete
                  v-model="lecturer"
                  label="Преподаватель"
                  placeholder="Выберите значение из списка"
                  :items="lecturersList"
                  item-text="fullName"
                  hide-no-data
                  hide-details
                  return-object
                  @change="setLink"
                >
                  <template #item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.fullName }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <v-btn text color="accent" @click="closeDialog">Отмена</v-btn>

          <v-btn
            :disabled="$v.$invalid"
            color="success"
            text
            @click="openReport"
          >
            Открыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { reportApi } from '@/api'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    dialog: Boolean,
    cycleId: Number,
    lecturersList: Array
  },

  data: () => ({
    lecturer: null,
    link: null
  }),

  validations() {
    return {
      lecturer: { required }
    }
  },

  methods: {
    setLink() {
      this.link = reportApi.dpoScheduleByTeacher(this.cycleId, this.lecturer?.emplRateId)
    },

    openReport() {
      this.$router.openNewTab(this.link)
      this.closeDialog()
    },

    closeDialog() {
      this.lecturer = null
      this.$emit('closeDialog')
    }
  }
}
</script>