<template>
  <div class="pr-4">
    <v-menu offset-y v-model="showBudgetMenu" v-if="!selectedRows.length">
      <template #activator="{ on }">
        <v-btn
          v-on="on"
          text
          color="accent"
          class="print-btn px-0"
          title="Печать анонимных договоров"
          :ripple="false"
        >
          <v-icon left class="mr-0" size="20">mdi-printer-outline</v-icon>
          <span class="ml-2">Анонимные договоры</span>
          <v-icon right class="ml-0">{{ showBudgetMenu ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item dense target="_blank" :to="reportApi.dpoListenerContract(cycleId)">
          <v-list-item-title class="list-text">
            Печать договора бюджет
          </v-list-item-title>
        </v-list-item>

        <v-list-item dense target="_blank" :to="reportApi.dpoListenerContractNMO(cycleId)">
          <v-list-item-title class="list-text">
            Печать договора НМО
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn
      v-else
      text
      color="accent"
      class="print-btn px-0"
      title="Печать договора бюджет"
      :ripple="false"
      target="_blank"
      :to="reportApi.dpoListenerContractForListOfListeners(selectedRows)"
    >
      <v-icon left class="mr-0" size="20">mdi-printer-outline</v-icon>
      <span class="ml-2">Печать договора бюджет</span>
    </v-btn>

    <v-menu offset-y v-model="showNmoMenu" v-if="listenersList.length && !selectedRows.length">
      <template #activator="{ on }">
        <v-btn
          v-on="on"
          text
          color="accent"
          class="print-btn px-0 ml-6"
          title="Печать договоров для всех"
          :ripple="false"
        >
          <v-icon left class="mr-0" size="20">mdi-printer-outline</v-icon>
          <span class="ml-2">Договоры для всех</span>
          <v-icon right class="ml-0">{{ showNmoMenu ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item dense target="_blank" :to="reportApi.dpoListenerContractByCycle(cycleId)">
          <v-list-item-title class="list-text">
            Печать договора бюджет
          </v-list-item-title>
        </v-list-item>

        <v-list-item dense target="_blank" :to="reportApi.dpoListenerContractNMOByCycle(cycleId)">
          <v-list-item-title class="list-text">
            Печать договора НМО
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn
      v-if="selectedRows.length"
      text
      color="accent"
      class="print-btn px-0 ml-6"
      title="Печать договора НМО"
      :ripple="false"
      target="_blank"
      :to="reportApi.dpoListenerContractNMOForListOfListeners(selectedRows)"
    >
      <v-icon left class="mr-0" size="20">mdi-printer-outline</v-icon>
      <span class="ml-2">Печать договора НМО</span>
    </v-btn>
  </div>
</template>

<script>
import { reportApi } from '@/api'

export default {
  name: 'PrintContractsDialogButtons',

  props: {
    cycleId: Number,
    listenersList: Array,
    selectedRows: Array
  },

  data: () => ({
    reportApi,
    showBudgetMenu: false,
    showNmoMenu: false
  })
}
</script>