<template>
	<v-row justify="center" v-show="dialog">
		<v-dialog :value="dialog" max-width="850" @input="closeDialog">
			<v-card class="dialog-card">
				<v-card-title class="headline accent--text pb-4">
          Печать договоров
        </v-card-title>

				<v-card-text outlined tile class="border-top px-2">
          <v-card class="elevation-0">
            <v-card-title class="pt-3 pb-1">
              <span class="accent--text">Слушатели</span>

              <v-spacer></v-spacer>

              <Buttons
                :cycleId="cycleId"
                :listenersList="listenersList"
                :selectedRows="selectedRows"
              />
            </v-card-title>

            <v-data-table
              class="base-table"
              fixed-header
              hide-default-footer
              :headers="headers"
              :items="listenersList"
              :items-per-page="-1"
            >
              <template #item="{ item, index }">
                <tr @click="rowClick(item)" :class="{ selectedRow: selectedRows.includes(item.id) }" :key="index">
                  <td class="text-center">{{ index + 1 }}</td>
                  <td>{{ item.fullName }}</td>
                </tr>
              </template>

              <template slot="no-data">
                <div class="py-3">Нет слушателей на данном цикле</div>
              </template>

              <template slot="no-results">
                <div class="py-3">Ничего не найдено</div>
              </template>
            </v-data-table>
          </v-card>
				</v-card-text>

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <v-btn text color="accent" @click="closeDialog">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import Buttons from '@/components/cycles/dialogs/printContracts/Buttons'

export default {
  name: 'PrintContractsDialog',

  components: {
    Buttons
  },

	props: {
		dialog: Boolean,
    cycleId: Number,
    listenersList: Array
	},

	data: () => ({
    headers: [
      { text: '№', value: 'index', sortable: false, align: 'center', width: '50px' },
      { text: 'ФИО', value: 'fullName', sortable: false, align: 'left' }
    ],
    selectedRows: []
	}),

	methods: {
    rowClick(item) {
      const indexInList = this.selectedRows.findIndex(el => el === item.id)
      indexInList !== -1 ? this.selectedRows.splice(indexInList, 1) : this.selectedRows.push(item.id)
    },

    closeDialog() {
			this.$emit('closeDialog')
		}
	}
}
</script>

<style scoped>
.base-table {
  min-height: 377px;
  max-height: 377px;
}
</style>